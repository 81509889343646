<template>
        <b-table
            :fields="table.columns"
            :items="getItems"
            bordered head-row-variant="dark" hover outlined
            table-variant="light"
            responsive="sm"
            striped
            thead-class="text-center"
            table-class="text-center"
            style="min-height: 450px"
            sticky-header
            @sort-changed="sortChanged"
        >
            <!-- header templates -->
            <template #thead-top="data">
                <b-tr variant="dark">
                    <b-th colspan="1">{{$tc('form.meta.data', 1)}}</b-th>
                </b-tr>
            </template>

            <template #head(action)="data"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- button add -->
                <b-button size="sm" variant="secondary" @click="addRecord()">
                    <b-icon-plus
                        scale="2"
                        variant="white"
                    ></b-icon-plus>
                </b-button>
            </template>

            <!-- body templates -->

            <!-- meta.name -->
            <template #cell(metaName)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <b-form-input
                    v-model="row.item.meta.name"
                    size="sm"
                ></b-form-input>
            </template>
            <!-- price -->
            <template #cell(price)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px; width: 100px"
            >
                <b-input-group
                    :append="!getCurrency.currency_prepend ? getCurrency.markShort : null"
                    :prepend="getCurrency.currency_prepend ? getCurrency.markShort : null"
                    size="sm"
                >
                    <b-form-input
                        v-model.number="row.item.price"
                        class="text-right no-spinners"
                        type="number"
                        variant="primary"
                        @keypress="__onlyDecimalKey($event)"
                        size="sm"
                    ></b-form-input>
                </b-input-group>
            </template>

            <!-- notes -->
            <template #cell(locale)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- toggleDetails -->
                <b-icon
                    :icon="row.detailsShowing ? 'file-text-fill' : 'file-text'"
                    class="mt-2"
                    v-b-tooltip.hover
                    :title="$t('form.delete')"
                    scale="1.2"
                    style="cursor: pointer;"
                    variant="secondary"
                    @click="row.toggleDetails"
                ></b-icon>
            </template>
            <!-- action -->
            <template #cell(action)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- delete -->
                <b-icon-trash
                    v-b-tooltip.hover
                    class="mt-2"
                    :title="$t('form.delete')"
                    scale="1.2"
                    style="cursor: pointer;"
                    variant="danger"
                    @click="recordDelete(row.index)"
                ></b-icon-trash>

            </template>
            <!-- details -->
            <template #row-details="row">
                <b-card no-body class="m-0 p-0">
                    <b-form-group
                        label=""
                        class="mb-0 group-title p-0"
                        label-align="left"
                        label-class="font-weight-bold"
                        label-size=""
                    >
                        <b-tabs card pills>
                            <b-tab v-for="locale in getDataLocale"
                                   :active="locale.locale === $i18n.locale"
                                   :title="locale.locale"
                                   style="background: white"
                                   class="p-2"
                            >
                                <!-- locale:title -->
                                <b-col cols="12" lg="6" class="p-0 pr-1">
                                    <!-- title -->
                                    <b-form-group
                                        :label="$t('form.title')"
                                        class="mb-2 mr-sm-02 mb-sm-0"
                                        description=""
                                        label-class="mb-0"
                                        label-align="left"
                                        :label-for="'title_' + locale.locale"
                                    >
                                        <b-form-input
                                            :id="'title_' + locale.locale"
                                            v-model.lazy="row.item.locale[locale.locale].name"
                                            type="text"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <!-- locale: description -->
                                <b-form-group
                                    v-if="row.item.locale[locale.locale] !== undefined"
                                    :id="'group_editor_' + locale.locale"
                                    :label="$t('form.description')"
                                    label-align="left"
                                    :label-for="'editor_' + locale.locale"
                                    class="mt-2 col-sm-12 p-0"

                                    description=""
                                    label-class="mb-0"
                                >
                                    <vue-editor
                                        :id="'editor_' + locale.locale"
                                        v-model="row.item.locale[locale.locale].description"
                                        class="editor-red"
                                        :editorToolbar="__editorToolbar('normal')"
                                    ></vue-editor>
                                </b-form-group>
                            </b-tab>
                        </b-tabs>
                    </b-form-group>
                </b-card>
            </template>
        </b-table>
</template>

<script>

// @group ADVENTURE
export default {
    name: "FormRouteInsurance",
    components: {},
    props: {
        /**
         * @vuese
         * Locale data like active languages
         */
        data_locale: {
            type: Array,
            required: true
        },
        /**
         * @vuese
         * Record data for this component
         */
        record: {
            type: Array,
            default() {
                return []
            },
            required: true
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'metaName',
                        label: this.$t('form.title'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'price',
                        label: this.$tc('adventure.price', 0),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'locale',
                        label: this.$t('adventure.detail'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        show: true,
                        thStyle: {width: '60px'}    // 90px for 3 icons
                    },
                ],
                sortBy: 'departure',
                sortDesc: true,
            },
        }
    },
    mounted() {
    },
    methods: {
        sortChanged(event) {
            // console.log("sortChanged", event);
        },
        // <-- table data -->
        addRecord() {
            let locale = {};
            this.getDataLocale.forEach(data => {
                locale[data.locale] = {
                    name: null,
                    description: null
                };
            });
            // <--  -->
            this.record.unshift(
                {
                    key: this.__createUniqueKey(0),
                    meta:{
                        name: null,
                    },
                    price: null,
                    locale: locale,
                }
            );
        },
        recordDelete(index) {
            this.record.splice(index, 1);
        },
    },
    computed: {
        getCurrency() {
            return {
                markShort: '€',
                currency_prepend: false
            }
        },
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        notes: true,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        },
        getItems() {
            if (this.__isNull(this.record)) {
                this.addRecord();
            }
            return this.record;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
