<template>
    <div>   <!--TODO delete-->
        TODO: manjka podatek:
        <p>Število oseb</p>

        <b-table
            :fields="table.columns"
            :items="getItems"
            bordered head-row-variant="dark" hover outlined
            table-variant="light"
            responsive="sm"
            striped
            thead-class="text-center"
            table-class="text-center"
            style="min-height: 450px"
            sticky-header
            @sort-changed="sortChanged"
        >
            <!-- header templates -->
            <template #thead-top="data">
                <b-tr variant="dark">
                    <b-th colspan="2">{{$tc('adventure.departure', 0)}}</b-th>
                    <b-th colspan="2">{{$tc('adventure.arrival', 0)}}</b-th>
                </b-tr>
            </template>

            <template #head(action)="data"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- button add -->
                <b-button size="sm" variant="secondary" @click="addRecord()">
                    <b-icon-plus
                        scale="2"
                        variant="white"
                    ></b-icon-plus>
                </b-button>
            </template>

            <!-- body templates -->

            <!-- departure date-->
            <template #cell(departureDate)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <b-form-datepicker
                    v-model="row.item.departureDate"
                    :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                    :start-weekday="__localeContent.firstDayOfWeek"
                    :locale="__getVueLocale()"
                    label-help=""
                    label-no-date-selected=""
                    size="sm"
                ></b-form-datepicker>
            </template>
            <!-- departure time -->
            <template #cell(departureTime)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <div class="d-flex justify-content-center align-items-center">
                    <b-form-input
                        v-model="row.item.departureTime"
                        type="time"
                        size="sm"
                        style="width: 90px"
                        @click.stop=""
                    ></b-form-input>
                </div>
            </template>
            <!-- arrival date-->
            <template #cell(arrivalDate)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <b-form-datepicker
                    v-model="row.item.arrivalDate"
                    :date-format-options="{year: 'numeric', month: 'long', day: '2-digit'}"
                    :start-weekday="__localeContent.firstDayOfWeek"
                    :locale="__getVueLocale()"
                    label-help=""
                    label-no-date-selected=""
                    size="sm"
                    :min="record[row.index].departureDate"
                ></b-form-datepicker>
            </template>
            <!-- arrival time -->
            <template #cell(arrivalTime)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <div class="d-flex justify-content-center align-items-center">
                    <b-form-input
                        v-model="row.item.arrivalTime"
                        type="time"
                        size="sm"
                        style="width: 90px"
                    ></b-form-input>
                </div>
            </template>
            <!-- notes -->
            <template #cell(locale)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- toggleDetails -->
                <b-icon
                    :icon="row.detailsShowing ? 'file-text-fill' : 'file-text'"
                    class="mt-2"
                    v-b-tooltip.hover
                    :title="$t('form.delete')"
                    scale="1.2"
                    style="cursor: pointer;"
                    variant="secondary"
                    @click="row.toggleDetails"
                ></b-icon>
            </template>
            <!-- action -->
            <template #cell(action)="row"
                      class="d-flex justify-content-center align-items-center"
                      style="height: 30px;"
            >
                <!-- delete -->
                <b-icon-trash
                    class="mt-2"
                    v-b-tooltip.hover
                    :title="$t('form.delete')"
                    scale="1.2"
                    style="cursor: pointer;"
                    variant="danger"
                    @click="recordDelete(row.index)"
                ></b-icon-trash>

            </template>
            <!-- details -->
            <template #row-details="row">
                <b-card no-body class="m-0 p-0">
                    <b-form-group
                        label=""
                        class="mb-0 group-title p-0"
                        label-align="left"
                        label-class="font-weight-bold"
                        label-size=""
                    >
                        <tab-locale-editor
                            :data_locale="getDataLocale"
                            :label="$t('form.notes')"
                            :record="row.item.locale"
                            color_class="editor-green"
                            json_key="notes"
                        ></tab-locale-editor>
                    </b-form-group>
                </b-card>
            </template>
        </b-table>
    </div>
</template>

<script>
// @group ADVENTURE
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";

export default {
    name: "FormRouteTravelDates",
    components: {TabLocaleEditor},
    props: {
        /**
         * @vuese
         * Locale data like active languages
         */
        data_locale: {
            type: Array,
            required: true
        },
        /**
         * @vuese
         * Record data for this component
         */
        record: {
            type: Array,
            default() {
                return []
            },
            required: true
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'departureDate',
                        label: this.$t('date_time.date'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'departureTime',
                        label: this.$t('date_time.time'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'arrivalDate',
                        label: this.$t('date_time.date'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'arrivalTime',
                        label: this.$t('date_time.time'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'locale',
                        label: this.$t('adventure.detail'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        show: true,
                        thStyle: {width: '60px'}    // 90px for 3 icons
                    },
                ],
                sortBy: 'departure',
                sortDesc: true,
            },
        }
    },
    mounted() {
    },
    methods: {
        sortChanged(event) {
            // console.log("sortChanged", event);
        },
        // <-- table data -->
        addRecord() {
            this.record.unshift(
                {
                    key: this.__createUniqueKey(0),
                    departureDate: null,
                    departureTime: null,
                    arrivalDate: null,
                    arrivalTime: null,
                    locale: {},
                }
            );
        },
        recordDelete(index) {
            this.record.splice(index, 1);
        },
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        notes: true,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        },
        getItems() {
            if (this.__isNull(this.record)) {
                this.addRecord();
            }
            return this.record;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
