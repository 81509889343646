<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        scrollable
        size="xl"
        @close="$bvModal.hide($options.name)"
        @hide="cancelModal"
    >
        <!-- body -->
        <b-overlay v-if="!__isNull(record)" :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-form ref="form">
                <!-- id, active -->
                <form-id-active :record.sync="record" :private-visible="true"></form-id-active>
                <!-- left tabs -->
                <b-card no-body>
                    <b-tabs v-model="tabIndex" card pills vertical>
                        <!-- tab management -->
                        <b-tab class="my-2 py-0 px-2">
                            <template #title>
                                <!--                                <b-icon v-if="tabBasicValidity === false"-->
                                <!--                                        class="mb-1"-->
                                <!--                                        font-scale="0.7"-->
                                <!--                                        icon="exclamation-circle-fill"-->
                                <!--                                        variant="danger">-->
                                <!--                                </b-icon>-->
                                {{$t('adventure.management')}}
                            </template>
                            <b-card-text class="mx-0">
                                <!-- meta label -->
                                <b-form-group
                                    :label="$t('adventure.management')"
                                    class="mb-0 group-title p-0"
                                    label-class="py-0 pl-3 font-weight-bold"
                                    label-size="md"
                                >
                                </b-form-group>
                                <!-- meta: contractor, owner -->
                                <b-row class="mx-0">
                                    <!-- contractor -->
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <b-form-group
                                            :label="$t('adventure.contractor')"
                                            description=""
                                            class="my-1 mb-sm-0"
                                            label-class="mb-0"
                                            label-for="select-contractor_id"
                                        >
                                            <b-form-select
                                                id="select-contractor_id"
                                                v-model="record.contractor_id"
                                                :options="getContractors"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                            ></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <!-- owner -->
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <!-- meta: name -->
                                        <b-form-group
                                            :label="$t('adventure.owner')"
                                            description=""
                                            class="my-1 mb-sm-0"
                                            label-class="mb-0"
                                            label-for="select-owner_id"
                                        >
                                            <b-form-select
                                                id="select-owner_id"
                                                v-model="record.owner_id"
                                                :options="getOwners"
                                                class="mb-2 mr-sm-2 mb-sm-0"
                                                required
                                            ></b-form-select>
                                            <!-- other owner -->
                                            <b-form-group
                                                v-if="record.owner_id === 'other'"
                                                class="mt-1 mb-0 p-0 mb-sm-0"
                                                :label="$t('adventure.other_owner')"
                                                label-class="mb-0"
                                                label-for="owner-text_area"
                                            >
                                                <b-form-textarea
                                                    id="owner-text_area"
                                                    size="sm"
                                                    type="text"
                                                    v-model="record.content.owner"
                                                    rows="4"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-col>

                                </b-row>
                            </b-card-text>
                        </b-tab>
                        <!-- tab: info -->
                        <b-tab :active="record.id > 0" class="my-2 py-0 px-2">
                            <template #title>
                                <b-icon v-if="tabBasicValidity === false"
                                        class="mb-1"
                                        font-scale="0.7"
                                        icon="exclamation-circle-fill"
                                        variant="danger">
                                </b-icon>
                                {{$t('form.tab.info')}}
                            </template>
                            <b-card-text class="mx-0">
                                <!-- meta label -->
                                <b-form-group
                                    :label="$tc('form.meta.data', 0)"
                                    class="mb-0 group-title p-0"
                                    label-class="py-0 pl-3 font-weight-bold"
                                    label-size="md"
                                >
                                </b-form-group>
                                <!-- meta: name, subtitle -->
                                <b-row class="mx-0">
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <!-- meta: name -->
                                        <b-form-group
                                            :label="$t('adventure.title') + '*'"
                                            description=""
                                            class="mb-sm-0"
                                            label-class="mb-0"
                                            label-for="meta_name"
                                        >
                                            <b-form-input
                                                id="meta_name"
                                                v-model="record.meta.name"
                                                type="text"
                                                :state="nameValidity"
                                            ></b-form-input>
                                            <b-form-invalid-feedback :state="nameValidity">
                                                {{$t('form.required_field')}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <!-- meta: subtitle -->
                                        <b-form-group
                                            :label="$t('form.subtitle')"
                                            description=""
                                            class="mb-sm-0"
                                            label-class="mb-0"
                                            label-for="meta_subtitle"
                                        >
                                            <b-form-input
                                                id="meta_subtitle"
                                                v-model="record.meta.subtitle"
                                                type="text"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <!-- content -->
                                <!-- label content -->
                                <b-form-group
                                    :label="$t('form.content')"
                                    class="mt-2 mb-0 group-title p-0"
                                    label-class="py-0 pl-3 font-weight-bold"
                                    label-size="md"
                                >
                                </b-form-group>
                                <!-- tour languages -->
                                <b-row class="mx-0">
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <b-form-group
                                            label="Jezik vodenja"
                                            description=""
                                            class="mb-sm-0"
                                            label-class="mb-0"
                                            label-for="order"
                                        >
                                            <p class="mt-1">
                                                Manjka jezik vodenja!!! <!-- // TODO Add tour language -->
                                            </p>
                                        </b-form-group>
                                    </b-col>
                                    <!-- order -->
                                    <b-col cols="12" xl="6" class="p-0 px-md-1 px-sm-0">
                                        <b-form-group
                                            :label="$t('form.order')"
                                            description=""
                                            class="mb-sm-0"
                                            label-class="mb-0"
                                            label-for="order"
                                        >
                                            <b-form-input
                                                id="order"
                                                type="number"
                                                v-model="record.order"
                                            ></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <!-- basic locale -->
                                <b-form-group
                                    :label="$t('form.basic.data')"
                                    class="mt-2 mb-0 group-title p-0"
                                    label-class="py-0 pl-3 font-weight-bold"
                                    label-size="md"
                                >
                                    <form-route-locale-basic
                                        class="mt-2 mx-1"
                                        :data_locale="data.locale"
                                        :record="record.locale"
                                    ></form-route-locale-basic>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab content -->
                        <b-tab class="my-2 py-0 px-2" :title="$t('form.content')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.content')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('adventure.content_detail', 1)"
                                            :record="record.locale"
                                            color_class="editor-green"
                                            json_key="content"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab school excursion -->
                        <b-tab class="my-2 py-0 px-2">
                            <template #title>
                                <!--                                <b-icon v-if="tabBasicValidity === false"-->
                                <!--                                        class="mb-1"-->
                                <!--                                        font-scale="0.7"-->
                                <!--                                        icon="exclamation-circle-fill"-->
                                <!--                                        variant="danger">-->
                                <!--                                </b-icon>-->
                                {{$t('adventure.school_excursion')}}
                            </template>
                            <b-card-text>
                                <b-form-group
                                    :label="$t('adventure.school_excursion')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$t('adventure.school_excursion_detail')"
                                            :record="record.locale"
                                            color_class="editor-blue"
                                            json_key="schoolExcursion"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab travel dates -->
                        <b-tab class="my-2 py-0 px-2" :title="$tc('adventure.travel_date', 0)">
                            <b-card-text>
                                <b-form-group
                                    :label="$tc('adventure.travel_date', 0)"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <form-route-travel-dates
                                        v-if="data.locale !== undefined"
                                        :record="record.extended.travelDates"
                                        :data_locale="data.locale"
                                    />
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab transporter -->
                        <b-tab class="my-2 py-0 px-2" :title="$tc('adventure.transporter', 0)">
                            <b-card-text>
                                <b-form-group
                                    :label="$tc('adventure.transporter', 0)"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <form-route-transporter
                                        v-if="data.locale !== undefined"
                                        :record="record.extended.transporter"
                                        :data_locale="data.locale"
                                    />
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab insurance -->
                        <b-tab class="my-2 py-0 px-2">
                            <template #title>
                                <!--                                <b-icon v-if="tabBasicValidity === false"-->
                                <!--                                        class="mb-1"-->
                                <!--                                        font-scale="0.7"-->
                                <!--                                        icon="exclamation-circle-fill"-->
                                <!--                                        variant="danger">-->
                                <!--                                </b-icon>-->
                                <!--                                {{$t('form.tab.basic')}}-->
                                {{$tc('adventure.insurance', 0)}}
                            </template>
                            <b-card-text>
                                <b-form-group
                                    :label="$tc('adventure.insurance', 0)"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <form-route-insurance
                                        v-if="data.locale !== undefined"
                                        :record="record.extended.insurance"
                                        :data_locale="data.locale"
                                    />
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab pictures -->
                        <b-tab class="my-2 py-0 px-2" :title="$t('form.tab.picture')">
                            <PictureManagerDeprecated
                                class="mt-3"
                                :picture-manager-bus="pictureManagerBus"
                                @eventUpdate="eventPictureManagerUpdate"
                            ></PictureManagerDeprecated>
                        </b-tab>
                        <!-- tab miscellaneous -->
                        <b-tab class="my-2 py-0 px-2" :title="$t('form.miscellaneous')">
                            <b-card-text>
                                <b-form-group
                                    :label="$t('form.miscellaneous')"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('adventure.content_detail', 0)"
                                            :record="record.locale"
                                            color_class="editor-yellow"
                                            json_key="miscellaneous"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>
                        <!-- tab experiences -->
                        <b-tab class="my-2 py-0 px-2" :title="$tc('adventure.experience.main', 1)">
                            <b-card-text>
                                <b-form-group
                                    :label="$tc('adventure.experience.main', 1)"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <form-route-experience-table
                                        v-if="data.experiences !== undefined"
                                        :record.sync="record.experiences"
                                        :data="data.experiences"
                                    />
                                </b-form-group>
                            </b-card-text>
                        </b-tab>

                        <!-- tab gtac (General Terms And Condition) -->
                        <b-tab class="my-2 py-0 px-2" :title="$tc('business.gtac', 1)">
                            <b-card-text>
                                <b-form-group
                                    :label="$tc('business.gtac', 0)"
                                    class="mb-0 group-title p-0"
                                    label-align-sm="left"
                                    label-class="pl-3 font-weight-bold"
                                    label-cols-sm="12"
                                    label-size=""
                                >
                                    <b-card no-body>
                                        <tab-locale-editor
                                            :data_locale="data.locale"
                                            :label="$tc('business.gtac', 0)"
                                            :record="record.locale"
                                            color_class="editor-blue"
                                            json_key="gtac"
                                        ></tab-locale-editor>
                                    </b-card>
                                </b-form-group>
                            </b-card-text>
                        </b-tab>

                        <!-- tab price -->
                        <b-tab class="my-2 py-0 px-2">
                            <template #title>
                                <!--                                <b-icon v-if="tabBasicValidity === false"-->
                                <!--                                        class="mb-1"-->
                                <!--                                        font-scale="0.7"-->
                                <!--                                        icon="exclamation-circle-fill"-->
                                <!--                                        variant="danger">-->
                                <!--                                </b-icon>-->
                                <!--                                {{$t('form.tab.basic')}}-->
                                {{$tc('adventure.travel_price', 0)}}
                            </template>
                            <b-card-text>
                                <p>Izračunana informativna cena</p>
                                <p>Možnost dodajanja različnih cen: vodnika, prevajalca, vstopnine....</p>
                                <p>Cena SMRK paketa</p>
                                <p>Cena agencije</p>
                                <p>...</p>
                                <p>Končna cena</p>
                                <p>templateKey</p>
                            </b-card-text>
                        </b-tab>

                        <!-- conclusion -->
                        <b-tab class="my-2 py-0 px-2">
                            <template #title>
                                <!--                                <b-icon v-if="tabBasicValidity === false"-->
                                <!--                                        class="mb-1"-->
                                <!--                                        font-scale="0.7"-->
                                <!--                                        icon="exclamation-circle-fill"-->
                                <!--                                        variant="danger">-->
                                <!--                                </b-icon>-->
                                <!--                                {{$t('form.tab.basic')}}-->
                                {{$tc('adventure.conclusion', 0)}}
                            </template>
                            <b-card-text>
                                <p>Dodajanje opomb, predlogov... o potovanju</p>
                                <p>Podroben prikaz ocen (reviews)</p>
                                <p>...</p>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form>
        </b-overlay>
        <!-- footer -->
        <template #modal-footer="{cancel, save, saveClose}">
            <b-button size="sm" variant="danger" @click="$bvModal.hide($options.name)">
                Cancel
            </b-button>
            <b-button size="sm" variant="success" @click="saveModal">
                Save
            </b-button>
            <b-button size="sm" variant="success" @click="saveCloseModal">
                Save and close
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import Vue from "vue";
import PictureManagerDeprecated
    from "@/components/general/elements/pictures/pictures_manager_deprecated/PictureManagerDeprecated";
import FormRouteTransporter from "@/components/adventure/route/elements/FormRouteTransporter";
import FormRouteInsurance from "@/components/adventure/route/elements/FormRouteInsurance";
import FormRouteTravelDates from "@/components/adventure/route/elements/FormRouteTravelDates";
import FormRouteLocaleSchoolExcursion
    from "@/components/adventure/route/elements/FormRouteLocaleSchoolExcursion";
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";
import FormRouteLocaleBasic from "@/components/adventure/route/elements/FormRouteLocaleBasic";
import FormIdActive from "@/components/general/elements/formElements/FormIdActive";
import FormRouteExperienceTable from "@/components/adventure/route/elements/FormRouteExperienceTable";

//@group Form.ADVENTURE
export default {
    name: "ModalFormRoute",
    components: {
        FormRouteExperienceTable,
        PictureManagerDeprecated,
        FormRouteTransporter,
        FormRouteInsurance,
        FormRouteTravelDates,
        FormRouteLocaleSchoolExcursion, TabLocaleEditor, FormRouteLocaleBasic, FormIdActive
    },
    props: {
        // Unique key to define modal window
        localeKey: {
            type: String,
            default: null,
            required: true
        },
        // Uri for calling api
        uri: {
            type: Object,
            default: null,
            required: false
        },
        // Bus object for events
        modalBus: {
            type: Object,
            default() {
                return {}
            },
            required: false
        },
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            tabIndex: 0,
            data: {},
            division: 'route',
            recordId: 0,
            recordDefault: {
                id: null,
                owner_id: null,
                contractor_id: null,
                meta: {
                    name: null,
                    subtitle: null,
                },
                content: {},
                locale: {},
                extended: {
                    travelDates: [],
                    transporter: [],
                    insurance: [],
                },
                // <-- pictures -->
                pictures_order: [],
                pictures: [],
                // <-- experiences -->
                experiences: [],
                // <--  -->
                order: 0,
                active: true,
            },
            record: null,
            // <-- validity -->
            validityDefault: {
                check: false,
            },
            tabValidity: {},
            // <-- errors -->
            errorsDefault: {
                message: null,
            },
            errors: {
                message: null
            },
            // <--  -->
            pictureManagerBus: new Vue(),
        }
    },
    mounted() {
        this.modalBus.$on('modalOpen', (recordId) => {
            this.recordId = recordId;
            this.modalOpen();
        });
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetValidity() {
            this.$set(this, 'tabValidity', this.__clone(this.validityDefault));
        },
        resetErrors() {
            this.$set(this, 'errors', this.__clone(this.errorsDefault));
        },
        // <-- modal deprecated -->
        modalOpen() {
            this.resetValidity();
            this.resetErrors();
            // <--  -->
            if (this.recordId > 0) {
                this.loadEdit();
                return;
            }
            this.loadNew();
        },
        hideModal(data) {
            // this.$emit('refresh', data);
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.showOverlay(false);
            });
        },
        cancelModal() {
            // this.updateRecord({});
            this.hideModal(null);

        },
        saveModal(bvModalEvent) {
            // TODO clear  _showDetails from travelDate, insurance and transporter
            this.formSave(false);
        },
        saveCloseModal() {
            this.formSave(true);
        },
        // updateRecord(record) {
        //     this.$set(this, 'record', record);
        // },
        // <-- languages -->
        addSelectedLanguage(language) {
            /*            this.record.content.isGuidedTour = true;
                        if (language === null) {
                            return;
                        }
                        // <-- no guided tour -->
                        if (language === 0) {
                            this.record.content.isGuidedTour = false,
                                this.record.content.languageTourIds = [];
                            return;
                        }
                        // <--  -->
                        let selectedLanguages = this.record.content.languageTourIds;
                        // <-- check if exists -->
                        let exists = false;
                        selectedLanguages.every(v => {
                            if (language !== v.id) {
                                return false;
                            }
                            exists = true;
                            return true;
                        });
                        // <--  -->
                        if (!exists) {
                            this.record.content.languageTourIds.push(language);
                        }*/
        },
        removeSelectedLanguage(languageId) {
            /*            let selectedLanguages = this.record.content.languageTourIds;
                        // <--  -->
                        selectedLanguages.every((v, index) => {
                            if (languageId === v) {
                                selectedLanguages.splice(index, 1);
                                return false;
                            }
                            return true;
                        });*/
        },
        // <-- contacts -->
        contactUpdate(data) {
            this.$set(this.record.contact, data.key, data.values);
        },
        // <-- events pictureManager -->
        eventPictureManagerUpdate(data) {
            this.$set(this.record, 'pictures_order', data.pictures_order);
            this.$set(this.record, 'pictures', data.pictures);
        },
        // <-- api -->
        loadNew() {
            this.showOverlay(true);
            this.$set(this, 'record', this.__clone(this.recordDefault));
            this.$bvModal.show(this.$options.name);

            // <-- record -->
            // this.$nextTick(() => {
            this.$set(this, 'record', this.__clone(this.recordDefault));
            // this.$set(this, 'recordClone', this.__clone(this.record));
            let id = 0;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.uri1 + `/getForeignDataActive`, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    // <-- picture -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures);
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // on error executed
                });
        },
        loadEdit() {
            this.showOverlay(true);
            this.$set(this, 'record', this.__clone(this.recordDefault));
            this.$bvModal.show(this.$options.name);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main + `/${this.recordId}`, config)
                .then((response) => {
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    this.$set(this, 'record', response.data.record);
                    this.__mergeObjectDeep(this.recordDefault, this.record);
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.$nextTick(function () {
                        this.pictureManagerBus.$emit('recordLoad', recordPictures)
                    });
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    console.log("error:", error.data);
                });
        },
        // <-- form -->
        formSave(close) {
            this.tabValidity.check = true;
            let validity = true;
            // <-- check -->
            this.$nextTick(() => {
                Object.keys(this.tabValidity).forEach(key => {
                    if (key !== 'check' && !this.tabValidity[key]) {
                        validity = false;
                    }
                });
                // <-- save -->
                if (validity) {
                    if (this.record.id > 0) {
                        this.baseUpdateFormData(close);
                        return;
                    }
                    this.baseInsertFormData(close);
                }
            });
        },
        // <-- base -->
        baseInsertFormData(close) {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(close)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.main, formData, config)
                .then((response) => {
                    if (close) {
                        this.$emit('refresh', response.data);
                        this.$bvModal.hide(this.$options.name);
                        this.showOverlay(false);
                        return;
                    }
                    // <-- update after save -->
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    this.$set(this, 'record', response.data.record);
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    // <--  -->
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
        baseUpdateFormData(close) {
            this.showOverlay(true);
            // <-- clear errors -->
            this.resetErrors();
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(close)
            };
            // <-- form data -->
            let formData = new FormData();
            // <-- prepare files for axios -->
            if (this.record.pictures !== undefined) {
                this.record.pictures.forEach((picture, index) => {
                    formData.append('division', this.division);
                    formData.append('files[' + index + ']', picture.file);
                    formData.append('keys[' + index + ']', picture.key);
                });
            }
            // <-- prepare data for axios -->
            formData.append('record', JSON.stringify(this.record));
            // <-- axios -->
            this.axios.post(this.uri.uri1 + '/form_data_update' + `/${this.record.id}`, formData, config)
                .then((response) => {
                    if (close) {
                        this.$emit('refresh', response.data);
                        this.$bvModal.hide(this.$options.name);
                        this.showOverlay(false);
                        return;
                    }
                    // <-- update after save -->
                    // <-- data -->
                    this.$set(this, 'data', response.data.data);
                    // <-- record -->
                    this.$set(this, 'record', response.data.record);
                    // <-- pictures -->
                    let recordPictures = {
                        pictures_order: this.record.pictures_order,
                        pictures: this.record.pictures
                    };
                    this.showOverlay(false);
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.message = error;
                    this.showOverlay(false);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        title: {
            get() {
                let record = this.record;
                if (this.recordId <= 0 || record === null || record.meta === undefined || record.meta.name === undefined) {
                    return this.$t('form.add') + ': ' + this.$tc(this.localeKey, 0).toLowerCase();
                }
                // <-- name -->
                let name = record.meta.name;
                // <--  -->
                return this.$tc(this.localeKey, 0) + ': ' + name;
            },
        },
        // <-- data -->
        getContractors() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // <-- check data -->
            if (this.__isNull(this.data) || this.__isNull(this.data.organisations)) {
                return array;
            }
            // <--  -->
            let items = this.data.organisations;
            items.forEach(item => {
                array.push({
                    text: (this.__isNull(item.name) || this.__isNull(item.name.name) ? item.name.full_name : item.name.name),
                    value: item.id,
                    taxpayer: item.taxpayer
                });
            });
            // <--  -->
            return array;
        },
        getOwners() {
            let array = [
                {
                    text: this.$t('form.choose_placeholder'),
                    value: null
                }
            ];
            // // <-- check data -->
            // if (this.__isNull(this.data) || this.__isNull(this.data.organisations)) {
            //     return array;
            // }
            // // <--  -->
            // let items = this.data.organisations;
            // items.forEach(item => {
            //     array.push({
            //         text: (this.__isNull(item.name) || this.__isNull(item.name.name) ? item.name.full_name : item.name.name),
            //         value: item.id,
            //         taxpayer: item.taxpayer
            //     });
            // });
            // <-- other -->
            array.push({
                text: '#Drugo#',
                value: 'other',
            });
            // <--  -->
            return array;
        },
        /*        getOwnershipTypes() {
                    let array = [];
                    // <-- check data -->
                    if (this.__isNull(this.data) || this.__isNull(this.data.ownershipTypes)) {
                        return array;
                    }
                    // <--  -->
                    let items = this.data.ownershipTypes;
                    let text = null;
                    items.forEach(item => {
                        text = `(${item.meta.name})`;
                        // <-- locale -->
                        if (!this.__isNull(item.locale) && !this.__isNull(item.locale.name)) {
                            text = item.locale.name;
                        }
                        // <-- push -->
                        array.push({
                            text: text,
                            value: item.id,
                            disabled: item.id === 3 // <-- disabled physical person -->
                        });
                    });
                    // <-- add placeholder on top -->
                    array.unshift({
                        text: this.$t('form.choose_placeholder'),
                        value: null
                    });
                    // <--  -->
                    return array;
                },
                getOwnerVisible() {
                    let visible = false;
                    let visibleLegalEntity = false;
                    let visiblePhysicalPerson = false;
                    // <--  -->
                    switch (this.record.ownership_type_id) {
                        case 2:     // <-- legal entity -->
                            visible = true;
                            visibleLegalEntity = true;
                            visiblePhysicalPerson = false;
                            break;
                        case 3:     // <-- physical person -->
                            visible = true;
                            visibleLegalEntity = false;
                            visiblePhysicalPerson = true;
                            break;
                    }
                    // <--  -->
                    return {
                        visible: visible,
                        visibleLegalEntity: visibleLegalEntity,
                        visiblePhysicalPerson: visiblePhysicalPerson
                    };
                },
                getOrganisations() {
                    let array = [
                        {
                            text: this.$t('form.choose_placeholder'),
                            value: null
                        }
                    ];
                    // <-- check data -->
                    if (this.__isNull(this.data) || this.__isNull(this.data.organisations)) {
                        return array;
                    }
                    // <--  -->
                    let items = this.data.organisations;
                    items.forEach(item => {
                        array.push({
                            text: (this.__isNull(item.name) || this.__isNull(item.name.name) ? item.name.full_name : item.name.name),
                            value: item.id,
                            taxpayer: item.taxpayer
                        });
                    });
                    // <--  -->
                    return array;
                },
                getAddress() {
                    // <-- new record -->
                    if (this.record.id === undefined) {
                        return this.record.address;
                    }

                    // <-- edit record -->
                    let record = this.record;
                    let address = this.__clone(this.recordDefault.address);

                    address.state_id = this.__isNull(record.post_office) ? record.address.state_id : record.post_office.state_id;
                    address.postOffice_id = record.post_office_id;
                    address.region_id = record.region_id;
                    address.street = record.address.street;
                    address.full = record.address.full;
                    // <--  -->
                    this.$set(this.record, 'address', address);
                    return this.record.address;
                },*/
        // <-- validity: tab basic -->
        nameValidity() {
            if (this.tabValidity === undefined || this.tabValidity.check === false) {
                return null;
            }
            // <--  -->
            let validity = !this.__isNull(this.record.meta.name);
            return validity ? null : false;
        },
        tabBasicValidity() {
            let tabValidity = true;
            tabValidity = tabValidity === false || this.nameValidity === false ? false : tabValidity;
            // <--  -->
            this.$set(this.tabValidity, 'tabBasic', tabValidity);
            return tabValidity
        },
        // <-- validity: location tab -->
        /*        latitudeValidity() {
                    if (this.tabValidity === undefined || this.tabValidity.check === false) {
                        return null;
                    }
                    // <--  -->
                    let validity = !this.__isNull(this.record.content.coordinates.latitude);
                    return validity ? null : false;
                },
                longitudeValidity() {
                    if (this.tabValidity === undefined || this.tabValidity.check === false) {
                        return null;
                    }
                    // <--  -->
                    let validity = !this.__isNull(this.record.content.coordinates.longitude);
                    return validity ? null : false;
                },
                tabLocationValidity() {
                    let tabValidity = true;
                    // <--  -->
                    tabValidity = tabValidity === false || this.latitudeValidity === false ? false : tabValidity;
                    tabValidity = tabValidity === false || this.longitudeValidity === false ? false : tabValidity;
                    // <--  -->
                    this.$set(this.tabValidity, 'tab_location', tabValidity);
                    return tabValidity ? null : false;
                },*/
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
