<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-container class="mt-3" fluid>
                <!-- table toolbar -->
                <table-toolbar
                    :columns-filter="tableToolbarColumns"
                    :columns-show="tableToolbarColumns"
                    :locale-key="localeKey"
                    :options="tableToolbar.options"
                    :response.sync="tableToolbar.response"
                    @eventNew="toolbarTableEventNew"
                    @eventRefresh="toolbarTableEventRefresh"
                ></table-toolbar>
                <!-- table -->
                <b-table
                    id="main-table"
                    :current-page="tableToolbar.options.pagination.currentPage"
                    :fields="tableColumns"
                    :filter="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.search"
                    :filterIncludedFields="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.columnsOn"
                    :items="tableItems"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"
                    bordered head-row-variant="dark" hover outlined
                    responsive
                    striped
                    table-variant="light"
                    thead-class="text-center"
                    @filtered="onFiltered"
                >
                    <!-- id -->
                    <template v-slot:cell(id)="row">
                        <div class="d-flex justify-content-center">
                            {{row.item.id}}
                        </div>
                    </template>
                    <!-- order -->
                    <template v-slot:cell(order)="row">
                        <div class="d-flex justify-content-center">
                            {{row.item.order}}
                        </div>
                    </template>
                    <!-- active -->
                    <template v-slot:cell(active)="row">
                        <div class="d-flex justify-content-around">
                            <b-icon-sun
                                :variant="row.item.active ? 'success' : 'danger'"
                                scale="1.2"
                            ></b-icon-sun>
                        </div>
                    </template>
                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div class="d-flex justify-content-around">
                            <!--                            &lt;!&ndash; detail show &ndash;&gt;
                                                        <b-icon-caret-down-square
                                                            v-if="!row.detailsShowing"
                                                            v-b-tooltip.hover
                                                            :title="$t('form.detail_show')"
                                                            scale="1.2"
                                                            style="cursor: pointer; margin-right: 3px;"
                                                            variant="secondary"
                                                            @click="row.toggleDetails"
                                                        ></b-icon-caret-down-square>
                                                        &lt;!&ndash; detail hide &ndash;&gt;
                                                        <b-icon-caret-up-square-fill
                                                            v-if="row.detailsShowing"
                                                            v-b-tooltip.hover
                                                            :title="$t('form.detail_hide')"
                                                            scale="1.2"
                                                            style="cursor: pointer; margin-right: 3px;"
                                                            variant="secondary"
                                                            @click="row.toggleDetails"
                                                        ></b-icon-caret-up-square-fill>-->
                            <!-- edit -->
                            <b-icon-file-text
                                v-if="!row.item.system"
                                :title="$t('form.edit')"
                                scale="1.2"
                                style="cursor: pointer; margin-right: 3px;"
                                variant="secondary"
                                @click="recordEdit(row.item)"
                            ></b-icon-file-text>
                            <!-- delete -->
                            <b-icon-trash
                                v-if="!row.item.system"
                                :title="$t('form.delete')"
                                scale="1.2"
                                style="cursor: pointer;"
                                variant="danger"
                                @click="recordDelete(row.item)"
                            ></b-icon-trash>
                        </div>
                    </template>
                    <!-- row detail -->
                    <!--                                    <template v-slotBrisi:row-details="row">
                                                            &lt;!&ndash;                        <show-admin-organisation&ndash;&gt;
                                                            &lt;!&ndash;                            :content="tableRowDetail(row.item.id)"&ndash;&gt;
                                                            &lt;!&ndash;                            :data="data.data"&ndash;&gt;
                                                            &lt;!&ndash;                        ></show-admin-organisation>&ndash;&gt;
                                                        </template>-->
                </b-table>
                <!-- pagination -->
                <b-pagination
                    v-model="tableToolbar.options.pagination.currentPage"
                    :per-page="tableToolbar.options.pagination.perPage"
                    :total-rows="tableToolbar.options.filterCount"
                    aria-controls="main-table"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </b-container>
        </b-overlay>
        <!-- modalFormXxx -->
        <modal-form-route
            :form="form"
            :locale-key="localeKey"
            :modal-bus.sync="modalBus"
            :uri="uri"
            @refresh="modalEventRefresh"
        ></modal-form-route>
    </div>
</template>

<script>
import Vue from "vue";
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";
import ModalFormRoute from "@/components/adventure/route/ModalFormRoute";

export default {
    name: "Routes",
    components: {ModalFormRoute, TableToolbar},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            localeKey: 'adventure.route.main',
            uri: {
                main: `api/v1/adventure/routes`,
                uri1: `api/v1/adventure/route`,
            },
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'creator',
                        label: this.$tc('form.creator'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'name',
                        label: this.$tc('form.name'),
                        sortable: true,
                        alwaysShow: true,
                    },
                    {
                        key: 'contractor',
                        label: this.$t('adventure.contractor'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'owner',
                        label: this.$t('adventure.owner'),
                        sortable: true,
                        show: false,
                    },

                    {
                        key: 'schoolExcursion',
                        label: this.$t('adventure.school_excursion'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'departure',
                        label: this.$tc('adventure.departure', 1),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'arrival',
                        label: this.$tc('adventure.arrival', 1),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'insurance',
                        label: this.$tc('adventure.insurance', 1),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'experience',
                        label: this.$tc('adventure.experience.main', 1),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'price',
                        label: this.$tc('adventure.price', 0),
                        sortable: true,
                        show: false,
                    },
                    // <--  -->
                    {
                        key: 'order',
                        label: this.$t('form.order'),
                        sortable: true,
                        show: false,
                    },
                    {
                        key: 'active',
                        label: this.$t('form.active'),
                        sortable: true,
                        show: true,
                    },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            tableToolbar: {
                options: {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {}
            },
            form: {},
            // <-- set -->
            data: [],
            modalBus: new Vue(),
        }
    },
    mounted() {
        this.baseSelect();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- toolbarTable -->
        toolbarTableEventRefresh() {
            this.baseSelect();
        },
        toolbarTableEventNew() {
            this.recordNew();
        },
        // <-- modalForm -->
        modalEventRefresh(data) {
            this.$set(this, 'data', data);
        },
        // <-- table -->
        onFiltered(filteredItems) {
            this.tableToolbar.options.filterCount = filteredItems.length;
            this.tableToolbar.options.pagination.currentPage = 1;
        },
        tableRowDetail(id) {
            return this.data.records.find((n) => {
                if (n.id === id) {
                    if (n.post_office !== null && n.post_office[0] !== undefined && n.id === id) {
                        n.state = this.__getStateObject(this.data.data.states, n.post_office[0].state_id);
                    }
                    return n;
                }
            });
        },
        // <-- record -->
        recordNew() {
            this.modalBus.$emit('modalOpen', 0);
        },
        recordEdit(row) {
            this.modalBus.$emit('modalOpen', row.id);
        },
        recordDelete(row) {
            const h = this.$createElement;
            const messageHTML = h('div', [
                h('span', [
                    `${this.$t('form.delete_message.pre')} ${this.$tc(this.localeKey, 2)} `,
                    h('strong', row.name), this.$t('form.delete_message.post'),
                ])
            ]);
            // <-- message box -->
            this.$bvModal.msgBoxConfirm([messageHTML], {
                title: this.$t('form.please_confirm'),
                // size: 'sm',
                // buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: this.$t('form.delete'),
                cancelTitle: this.$t('form.cancel'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if (value) {
                        this.baseDelete(row.id);
                    }
                })
                .catch(err => {
                    // on error executed
                })
        },
        // <-- api calls -->
        baseSelect() {
            // this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.get(this.uri.main, config)
                .then((response) => {
                    this.$set(this, 'data', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
        baseDelete(record_id) {
            this.showOverlay(true);
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            // <-- axios -->
            this.axios.delete(this.uri.main + `/${record_id}`, config)
                .then((response) => {
                    this.data = response.data;
                    this.showOverlay(false);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
    },
    computed: {
        // <-- tableToolbar -->
        tableToolbarColumns() {
            return this.__getTableToolbarColumns(this.table.columns);
        },
        // <-- table -->
        tableColumns() {
            let columns = this.table.columns;
            let columnsShow = this.tableToolbar.response.columnsShow;
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            columns.forEach(column => {
                if (column.alwaysShow || (columnsShow != null && columnsShow.indexOf(column.key) >= 0)) {
                    values.push({
                        key: column.key,
                        label: column.label,
                        formatter: column.formatter,
                        sortable: column.sortable,
                        show: column.show,
                    });
                }
            });
            // <-- add column actions -->
            values.push({
                key: 'action',
                label: '',
                id: 0,
                sortable: false,
                thStyle: {width: '60px'}    // 90px for 3 icons
                // variant: 'danger',
            });
            // <--  -->
            return values;
        },
        tableItems() {
            if (this.data === undefined) {
                return [];
            }
            // <--  -->
            let records = this.data;


            // <-- check if exists -->
            if (records.length === 0) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            let name;

            records.forEach(record => {
                // <-- name -->
                name = record.meta.name;
                name = this.__isNull(record.locale) || this.__isNull(record.locale.name) ? name : record.locale.name;

                // <-- set item -->
                item = {
                    id: record.id,
                    creator: this.__getCreatorName(record.creator),
                    name: name,
                    order: record.order,
                    active: record.active,
                }
                items.push(item);
            });
            // <--  -->
            this.tableToolbar.options.filterCount = items.length;
            this.tableToolbar.options.rowsCount = items.length;
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
