<template>
    <b-table
        :fields="table.columns"
        :items="getItems"
        bordered head-row-variant="dark" hover outlined
        table-variant="light"
        responsive="sm"
        striped
        thead-class="text-center"
        table-class="text-center"
        style="min-height: 450px"
        sticky-header
        @sort-changed="sortChanged"
    >
        <!-- header templates -->
        <template #head(action)="data"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 30px;"
        >
            <!-- button add -->
            <b-button size="sm" variant="secondary" @click="addRecord()">
                <b-icon-plus
                    scale="2"
                    variant="white"
                ></b-icon-plus>
            </b-button>
        </template>

        <!-- body templates -->

        <!-- transporter -->
        <template #cell(transporter)="row"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 30px;"
        >
            <b-form-input
                v-model="row.item.transporter"
                size="sm"
            ></b-form-input>
        </template>
        <!-- route -->
        <template #cell(route)="row"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 30px; width: 100px"
        >
            <b-form-input
                v-model="row.item.route"
                size="sm"
            ></b-form-input>
        </template>

        <!-- notes -->
        <template #cell(locale)="row"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 30px;"
        >
            <!-- toggleDetails -->
            <b-icon
                :icon="row.detailsShowing ? 'file-text-fill' : 'file-text'"
                class="mt-2"
                v-b-tooltip.hover
                :title="$t('form.delete')"
                scale="1.2"
                style="cursor: pointer;"
                variant="secondary"
                @click="row.toggleDetails"
            ></b-icon>
        </template>
        <!-- action -->
        <template #cell(action)="row"
                  class="d-flex justify-content-center align-items-center"
                  style="height: 30px;"
        >
            <!-- delete -->
            <b-icon-trash
                v-b-tooltip.hover
                class="mt-2"
                :title="$t('form.delete')"
                scale="1.2"
                style="cursor: pointer;"
                variant="danger"
                @click="recordDelete(row.index)"
            ></b-icon-trash>

        </template>
        <!-- details -->
        <template #row-details="row">
            <b-card no-body class="m-0 p-0">
                <b-card no-body class="m-0 p-0">
                    <b-form-group
                        label=""
                        class="mb-0 group-title p-0"
                        label-align="left"
                        label-class="font-weight-bold"
                        label-size=""
                    >
                        <tab-locale-editor
                            :data_locale="getDataLocale"
                            :label="$t('form.notes')"
                            :record="row.item.locale"
                            color_class="editor-yellow"
                            json_key="notes"
                        ></tab-locale-editor>
                    </b-form-group>
                </b-card>
            </b-card>
        </template>
    </b-table>
</template>

<script>
import TabLocaleEditor from "@/components/general/elements/tabAccessories/TabLocaleEditor";
export default {
    name: "FormRouteTransporter",
    components: {TabLocaleEditor},
    props: {
        /**
         * @vuese
         * Locale data like active languages
         */
        data_locale: {
            type: Array,
            required: true
        },
        /**
         * @vuese
         * Record data for this component
         */
        record: {
            type: Array,
            default() {
                return []
            },
            required: true
        },
    },
    data() {
        return {
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'transporter',
                        label: this.$tc('adventure.transporter', 1),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'route',
                        label: this.$tc('adventure.route_text', 1),
                        sortable: true,
                        show: true,
                    },
                    {
                        key: 'locale',
                        label: this.$t('form.detail'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'action',
                        label: '',
                        sortable: false,
                        show: true,
                        thStyle: {width: '60px'}    // 90px for 3 icons
                    },
                ],
                sortBy: 'departure',
                sortDesc: true,
            },
        }
    },
    mounted() {
    },
    methods: {
        sortChanged(event) {
            // console.log("sortChanged", event);
        },
        // <-- table data -->
        addRecord() {
            let locale = {};
            this.getDataLocale.forEach(data => {
                locale[data.locale] = {
                    notes: null,
                };
            });
            // <--  -->
            this.record.unshift(
                {
                    key: this.__createUniqueKey(0),
                    transporter: null,
                    route: null,
                    locale: locale,
                }
            );
        },
        recordDelete(index) {
            this.record.splice(index, 1);
        },
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        notes: true,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        },
        getItems() {
            if (this.__isNull(this.record)) {
                this.addRecord();
            }
            return this.record;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
