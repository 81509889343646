<template>
    <b-container class="mt-3" fluid>

        <!-- table toolbar -->
        <!--        <table-toolbar-->
        <!--            :columns-filter="tableToolbarColumns"-->
        <!--            :columns-show="tableToolbarColumns"-->
        <!--            :locale-key="localeKey"-->
        <!--            :options="tableToolbar.options"-->
        <!--            :response.sync="tableToolbar.response"-->
        <!--            @eventNew="toolbarTableEventNew"-->
        <!--            @eventRefresh="toolbarTableEventRefresh"-->
        <!--        ></table-toolbar>-->
        <!-- table -->
        <b-table
            id="main-table"
            :current-page="tableToolbar.options.pagination.currentPage"
            :fields="tableColumns"
            :items="tableItems"
            :per-page="tableToolbar.options.pagination.perPage"
            :sort-by.sync="table.sortBy"
            :sort-desc.sync="table.sortDesc"
            bordered head-row-variant="dark" hover outlined
            responsive
            striped
            table-variant="light"
            thead-class="text-center"
            @filtered="onFiltered"
            @sort-changed="sortChanged"
            :no-local-sorting="true"
        >
            <!--            :filter="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.search"-->
            <!--            :filterIncludedFields="tableToolbar.response.filter === undefined ? null : tableToolbar.response.filter.columnsOn"-->
            <!-- id -->
            <template v-slot:cell(id)="row">
                <div class="d-flex justify-content-center">
                    {{row.item.id}}
                </div>
            </template>
            <!-- inRoute -->
            <template v-slot:cell(inRoute)="row">
                <div class="d-flex justify-content-center align-items-center">
                    <b-form-checkbox
                        v-model="row.item.inRoute"
                        @change="inRouteChange(row.item.id, row.item.inRoute)"
                    />
                    {{row.item.order}}
                </div>
            </template>
            <!--             actions
                                    <template v-slotBrisi:cell(action)="row">
                                        <div class="d-flex justify-content-around">
                                            &lt;!&ndash;                            &lt;!&ndash; detail show &ndash;&gt;
                                                                        <b-icon-caret-down-square
                                                                            v-if="!row.detailsShowing"
                                                                            v-b-tooltip.hover
                                                                            :title="$t('form.detail_show')"
                                                                            scale="1.2"
                                                                            style="cursor: pointer; margin-right: 3px;"
                                                                            variant="secondary"
                                                                            @click="row.toggleDetails"
                                                                        ></b-icon-caret-down-square>
                                                                        &lt;!&ndash; detail hide &ndash;&gt;
                                                                        <b-icon-caret-up-square-fill
                                                                            v-if="row.detailsShowing"
                                                                            v-b-tooltip.hover
                                                                            :title="$t('form.detail_hide')"
                                                                            scale="1.2"
                                                                            style="cursor: pointer; margin-right: 3px;"
                                                                            variant="secondary"
                                                                            @click="row.toggleDetails"
                                                                        ></b-icon-caret-up-square-fill>&ndash;&gt;
                                            &lt;!&ndash; edit &ndash;&gt;
                                            <b-icon-file-text
                                                v-if="!row.item.system"
                                                :title="$t('form.edit')"
                                                scale="1.2"
                                                style="cursor: pointer; margin-right: 3px;"
                                                variant="secondary"
                                                @click="recordEdit(row.item)"
                                            ></b-icon-file-text>
                                            &lt;!&ndash; delete &ndash;&gt;
                                            <b-icon-trash
                                                v-if="!row.item.system"
                                                :title="$t('form.delete')"
                                                scale="1.2"
                                                style="cursor: pointer;"
                                                variant="danger"
                                                @click="recordDelete(row.item)"
                                            ></b-icon-trash>
                                        </div>
                                    </template>-->
            <!-- row detail -->
            <!--                                    <template v-slotBrisi:row-details="row">
                                                    &lt;!&ndash;                        <show-admin-organisation&ndash;&gt;
                                                    &lt;!&ndash;                            :content="tableRowDetail(row.item.id)"&ndash;&gt;
                                                    &lt;!&ndash;                            :data="data.data"&ndash;&gt;
                                                    &lt;!&ndash;                        ></show-admin-organisation>&ndash;&gt;
                                                </template>-->
        </b-table>
        <!-- pagination -->
        <b-pagination
            v-model="tableToolbar.options.pagination.currentPage"
            :per-page="tableToolbar.options.pagination.perPage"
            :total-rows="tableToolbar.options.filterCount"
            aria-controls="main-table"
            limit="20"
            size="sm"
        ></b-pagination>
    </b-container>
</template>

<script>
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";

export default {
    name: "FormRouteExperienceTable",
    components: {TableToolbar},
    props: {
        /**
         * @vuese
         * All experiences or experiences selected according to a particular condition
         */
        data: {
            type: Array,
            required: true
        },
        /**
         * @vuese
         * Record data for this component
         */
        record: {
            type: Array,
            default() {
                return []
            },
            required: true
        },
    },
    data() {
        return {
            localeKey: 'adventure.experience.main',
            table: {
                items: [],
                fields: [],
                columns: [
                    {
                        key: 'id',
                        label: this.$t('form.id'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'destination',
                        label: this.$tc('adventure.destination.main', 0),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'name',
                        label: this.$tc('adventure.title'),
                        sortable: false,
                        alwaysShow: true,
                    },
                    {
                        key: 'subtitle',
                        label: this.$t('form.subtitle'),
                        sortable: false,
                        show: true,
                    },
                    {
                        key: 'inRoute',
                        label: this.$t('adventure.route.in_route'),
                        sortable: false,
                        show: true,
                    },
                ],
                sortBy: 'id',
                sortDesc: false,
            },
            tableToolbar: {
                options: {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                },
                response: {}
            },
        }
    },
    mounted() {
    },
    methods: {
        sortChanged(event) {
            // <-- checkbox ne dela ok, če je sorting vključen!!! naj se gladko j.... -->
            // console.log("sortChanged", event);
        },
        // <-- toolbarTable -->
        toolbarTableEventRefresh() {
            // this.baseSelect();
        },
        toolbarTableEventNew() {
            // this.recordNew();
        },
        // <-- table -->
        onFiltered(filteredItems) {
            this.tableToolbar.options.filterCount = filteredItems.length;
            this.tableToolbar.options.pagination.currentPage = 1;
        },
        tableRowDetail(id) {
            return this.data.records.find((n) => {
                if (n.id === id) {
                    return n;
                }
            });
        },
        // <-- record -->
        inRouteChange(id, value) {
            // <-- add to route -->
            if (value) {
                this.record.push(
                    {
                        experience_id: id,
                        order: this.record.length
                    }
                );
                return;
            }
            // <-- remove from route -->
            this.record.forEach((item, index) => {
                if (item.experience_id === id) {
                    this.record.splice(index, 1);
                }
            });
            // <-- correct order -->
            this.record.forEach((item, index) => {
                item.order = index;
            });
        }
    },
    computed: {
        // <-- tableToolbar -->
        tableToolbarColumns() {
            return this.__getTableToolbarColumns(this.table.columns);
        },
        // <-- table -->
        tableColumns() {
            return this.table.columns;
            /*            let columns = this.table.columns;
                        let columnsShow = this.tableToolbar.response.columnsShow;
                        // <-- check if exists -->
                        if (columns === undefined) {
                            return [];
                        }
                        // <--  -->
                        let values = [];
                        columns.forEach(column => {
                            if (column.alwaysShow || (columnsShow != null && columnsShow.indexOf(column.key) >= 0)) {
                                values.push({
                                    key: column.key,
                                    label: column.label,
                                    formatter: column.formatter,
                                    sortable: column.sortable,
                                    show: column.show,
                                });
                            }
                        });
                        // <-- add column actions -->
                        values.push({
                            key: 'action',
                            label: '',
                            id: 0,
                            sortable: false,
                            thStyle: {width: '60px'}    // 90px for 3 icons
                            // variant: 'danger',
                        });
                        // <--  -->
                        return values;*/
        },
        tableItems() {
            if (this.data === undefined) {
                return [];
            }
            // <--  -->
            let records = this.data;

            // <-- check if exists -->
            if (records.length === 0) {
                return [];
            }
            // <--  -->
            let items = [];
            let item = {};
            let name, destinationName, inRoute, order;
            records.forEach(record => {
                // <-- name -->
                name = record.meta.name;
                name = this.__isNull(record.locale) || this.__isNull(record.locale.name) ? name : record.locale.name;
                // <-- destinations -->
                destinationName = record.destination.meta_name;
                destinationName = this.__isNull(record.destination.locale) || this.__isNull(record.destination.locale.name) ? name : record.destination.locale.name;
                // <-- in route -->
                inRoute = false;
                order = null;
                this.record.every(item => {
                    if (item.experience_id === record.id) {
                        inRoute = true;
                        order = item.order;
                        return false;
                    }
                    return true;
                });
                // <-- set item -->
                item = {
                    id: record.id,
                    destination: destinationName,
                    name: name,
                    subtitle: record.locale.subtitle,
                    inRoute: inRoute,
                    order: order
                }
                items.push(item);
            });
            // <--  -->
            this.tableToolbar.options.filterCount = items.length;
            this.tableToolbar.options.rowsCount = items.length;
            // <--  -->
            return items;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
