<template>
    <b-tabs card pills>
        <b-tab v-for="locale in getDataLocale"
               :active="locale.locale === $i18n.locale"
               :title="locale.locale" style="background: white"
               class="p-2"
        >
            <!-- editor -->
            <b-form-group
                v-if="record[locale.locale] !== undefined"
                :id="'group_editor_' + locale.locale"
                :label="label"
                label-align="left"
                :label-for="'editor_' + locale.locale"
                class="col-sm-12 p-0"

                description=""
                label-class="mb-0"
            >
                <vue-editor
                    :id="'editor_' + locale.locale"
                    v-model="record[locale.locale][json_key]"
                    :class="color_class"
                    :editorToolbar="__editorToolbar(editorToolbarType)"
                ></vue-editor>
            </b-form-group>
        </b-tab>
    </b-tabs>
</template>

<script>
// @group GENERAL
export default {
    name: "TabLocaleEditor",
    components: {},
    props: {
        json_key: {
            type: String,
            default() {
                return 'locale_editor'
            }
        },
        label: {
            type: String,
            default() {
                return 'Label'
            }
        },
        color_class: {
            type: String,
            default() {
                return null
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        /**
         * @vuese
         * Types of Vue2-editor toolbar
         * Possibilities types are: <b>minimal</b>, <b>basic</b>, <b>normal</b> (null), <b>extended</b> and <b>full</b>.
         * Default value is null (normal).
         */
        editorToolbarType: {
            type: String,
            default() {
                return null
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {});
                }
            });
            // <--  -->
            return this.data_locale;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
