<template>
    <b-card no-body>
        <b-tabs card pills>
            <b-tab v-for="locale in getDataLocale"
                   :active="locale.locale === $i18n.locale"
                   :title="locale.locale"
                   class="border-danger py-1 m-1"
                   style="background: white"
            >
                <b-row>
                    <!-- column a -->
                    <b-col cols="12" lg="6" class="px-1">
                        <!-- title -->
                        <b-form-group
                            :label="$t('adventure.title')"
                            class="mb-2 mr-sm-02 mb-sm-0"
                            description=""
                            label-class="mb-0"
                            label-for="meta_title"
                        >
                            <b-form-input
                                :id="'title_' + locale.locale"
                                v-model.lazy="record[locale.locale].name"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                    <!-- column b -->
                    <b-col cols="12" lg="6" class="pl-1 pr-1">
                        <!-- subtitle -->
                        <b-form-group
                            :label="$t('form.subtitle')"
                            class="mb-2 mr-sm-02 mb-sm-0"
                            description=""
                            label-class="mb-0"
                            label-for="meta_subTitle"
                        >
                            <b-form-input
                                :id="'subTitle_' + locale.locale"
                                v-model.lazy="record[locale.locale].subtitle"
                                type="text"
                            ></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <!-- column a -->
                    <b-col cols="12" class="pl-1 pr-1">
                        <!-- editor: description -->
                        <b-form-group
                            v-if="record[locale.locale] !== undefined"
                            :id="'group_editor_' + locale.locale"
                            :label="$t('form.short_description')"
                            :label-for="'editor_' + locale.locale"
                            class="col-sm-12 p-0"
                            description=""
                            label-class="mb-0"
                        >
                            <vue-editor
                                :id="'editor_' + locale.locale"
                                v-model="record[locale.locale].description"
                                :class="color_class"
                                :editorToolbar="__editorToolbar('normal')"
                            ></vue-editor>
                        </b-form-group>
                    </b-col>
                    <!-- column b -->
                </b-row>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
export default {
    name: "FormRouteLocaleBasic",
    components: {},
    props: {
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        color_class: {
            type: String,
            default() {
                return 'editor-yellow'
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        validator(tag) {
            return tag.length > 2;
        },
    },
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        name: null,
                        subtitle: null,
                        description: null,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
