<template>
    <b-form-group
        :label="title"
        class="mb-0"
        label-class="font-weight-bold pt-0 ml-3"
        label-size="md"
    >
        <b-card no-body>
            <b-tabs card pills>
                <b-tab v-for="locale in getDataLocale"
                       :active="locale.locale === $i18n.locale"
                       :title="locale.locale" class="border-danger"
                       style="background: white"
                >
<!--                    <b-row class="mt-2">
                        &lt;!&ndash; column a &ndash;&gt;
                        <b-col cols="12" class="p-0 pr-1">
                            &lt;!&ndash; editor: formOfWork &ndash;&gt;
                            <b-form-group
                                v-if="record[locale.locale] !== undefined"
                                :id="'group_editor_' + locale.locale"
                                label="#Oblika dela#"
                                :label-for="'editor_' + locale.locale"
                                class="col-sm-12 p-0"
                                description=""
                                label-class="mb-0"
                            >
                                <vue-editor
                                    :id="'editor_' + locale.locale"
                                    v-model="record[locale.locale].formOfWork"
                                    :class="color_class"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        &lt;!&ndash; column b &ndash;&gt;
                    </b-row>-->
                    <!-- goals -->
                    <b-row class="mt-2">
                        <!-- column a -->
                        <b-col cols="12" class="p-0 pr-1">
                            <!-- editor: cilji -->
                            <b-form-group
                                v-if="record[locale.locale] !== undefined"
                                :id="'group_editor_' + locale.locale"
                                label="#Cilji#"
                                :label-for="'editor_' + locale.locale"
                                class="col-sm-12 p-0"
                                description=""
                                label-class="mb-0"
                            >
                                <vue-editor
                                    :id="'editor_' + locale.locale"
                                    v-model="record[locale.locale].goals"
                                    :class="color_class"
                                ></vue-editor>
                            </b-form-group>
                        </b-col>
                        <!-- column b -->
                    </b-row>
                </b-tab>
            </b-tabs>
        </b-card>
    </b-form-group>
</template>

<script>
export default {
    name: "FormRouteLocaleSchoolExcursion",
    components: {},
    props: {
        title: {
            type: String,
            default() {
                return this.$t('form.tab.locale');
            }
        },
        data_locale: {
            type: Array
        },
        record: {
            type: Object,
            default() {
                return {}
            }
        },
        color_class: {
            type: String,
            default() {
                return 'editor-blue'
            }
        },
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        getDataLocale() {
            if (this.__isNull(this.data_locale)) {
                return null;
            }
            // <-- update record with locale -->
            this.data_locale.forEach(locale => {
                if (this.record[locale.locale] === undefined) {
                    this.$set(this.record, locale.locale, {
                        isSchoolExcursion: true,
                        FormOfWork: null,
                        goals: null,
                    });
                }
            });
            // <--  -->
            return this.data_locale;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
